.maintenance-framework {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.maintenance-header {
    flex: 0 0 auto;
    height: 64px;
    padding: 16px;
}

    .maintenance-header .header-logo {
        height: 32px;
        display: block;
    }

.maintenance-content {
    padding: 32px;
    background-color: rgba(89, 209, 222, 0.15);
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.maintenance-content-container {
    min-height: 500px;
    width: 986px;
    flex: 1 1 auto;
    margin: 0 auto;
    background-image: url("../public/crane.svg");
    background-repeat: no-repeat;
    background-position-y: 100px;
    background-size: contain;
    background-origin: content-box;
}

.maintenance-content h1, h2, span, p, a {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #464646;
    line-height: 24px;
}

.maintenance-content .heading {
    font-size: 40px;
    font-weight: bold;
    line-height: 44px;
}

.maintenance-content .sub-heading {
    width: 558px;
    font-size: 20px;
    font-weight: 600;
}

.maintenance-content .social-container .social-text {
    margin: 0;
    margin-bottom: 8px;
}

.maintenance-content .social-container .social-logo {
    height: 32px;
    width: 32px;
    padding: 4px 4px 4px 0px;
}

@media (max-width: 1024px) and (min-width: 900px) {
    .maintenance-content-container {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .maintenance-header .header-logo {
        margin: 0px auto;
    }

    .maintenance-content {
        padding: 16px;
    }

    .maintenance-content-container {
        min-height: 0;
        width: 100%;
        background: none;
        display: flex;
        flex-direction: column;
    }

        .maintenance-content-container .mobile-background-container {
            flex: 1 1 auto;
            background-image: url("../public/crane.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50%;
        }

        .maintenance-content-container .heading, .sub-heading, .social-text, .social-links {
            flex: 0 0 auto;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        .maintenance-content-container .heading {
            margin: 0px auto 8px auto;
            font-size: 24px;
        }

        .maintenance-content-container .sub-heading {
            width: 100%;
            padding-left: 10%;
            padding-right: 10%;
            margin: 0px auto 8px auto;
            font-size: 16px;
        }
}
